const onGrecaptchaReady = (callback: () => void): void => {
    if (window.grecaptcha) {
        callback();
    } else {
        const interval = setInterval(() => {
            if (window.grecaptcha) {
                clearInterval(interval);
                window.grecaptcha.ready(callback);
            }
        }, 100);
    }
};

export const useGrecaptcha = (
    id: string,
    siteKey: string,
    callback: (key: string) => Promise<void> | void,
) => {
    if (!import.meta.client) {
        return {
            execute: async () => {},
            renderWidget: async () => {},
        };
    }

    let widgetId = -1;

    if (
        !document.head.querySelector(
            'script[src="https://www.google.com/recaptcha/api.js"]',
        )
    ) {
        const scriptTag = document.createElement('script');
        scriptTag.src = 'https://www.google.com/recaptcha/api.js';
        scriptTag.async = true;
        scriptTag.defer = true;
        document.head.appendChild(scriptTag);
    }

    const renderWidget = (): void => {
        if (!import.meta.client) {
            return;
        }

        onGrecaptchaReady(() => {
            const element = document.getElementById(id);
            if (!element) {
                console.error(
                    `GRecaptcha error: Element with id ${id} not found`,
                );
                return;
            }

            widgetId = window.grecaptcha.render(id, {
                sitekey: siteKey,
                callback: (key: string) => callback(key),
                size: 'invisible',
            });
        });
    };

    const execute = (): void => {
        // @ts-ignore
        onGrecaptchaReady(() => window.grecaptcha.execute(widgetId));
    };

    return { execute, renderWidget };
};
