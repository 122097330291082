<script setup lang="ts">
import { useGrecaptcha } from '~/utils/client/useGrecaptcha';

const props = withDefaults(
    defineProps<{
        id: string;
        siteKey: string;
        callback: (key: string) => Promise<void> | void;
        onBeforeExecute?: () => Promise<void> | void;
    }>(),
    {
        onBeforeExecute: () => {},
    },
);

const { execute, renderWidget } = useGrecaptcha(
    props.id,
    props.siteKey,
    props.callback,
);

/**
 * Magic happens here.
 * Grecaptcha script is appended to the head of the document.
 * Callback function is also appended to the head of the document.
 */
onMounted(async () => {
    try {
        await new Promise((resolve) => setTimeout(resolve, 300));
        renderWidget();
    } catch (e) {
        console.error(e);
    }
});

const executeGrecaptcha = async () => {
    try {
        await props.onBeforeExecute();
        execute();
    } catch (e) {
        console.error(e);
    }
};
</script>

<template>
    <div :key="id">
        <div :id="id"></div>
        <slot :execute="executeGrecaptcha" />
    </div>
</template>

<style>
.grecaptcha-badge {
    visibility: hidden;
    display: none !important;
}
</style>
